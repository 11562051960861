export default defineNuxtPlugin(async nuxtApp => {
    const runtimeConfigs = useRuntimeConfig()

    try {
        const { data: data } = await useFetch(`${runtimeConfigs.public.API_URL}/api/v1/settings/basic`)

        const final = data.value.data.reduce(function(acc, x) {
            for (var key in x) acc[key] = x[key];
            return acc;
        }, {});

        nuxtApp.$settings = final;
    } catch (error) {
        console.log('Houve um erro ao carregar as configurações do site.');
        console.log(error)

        const defaultSettings = {
            general_site_logo: "loading.png",
            general_site_title: "BR4BET",
            general_site_color: '#f7bf07',

            withdraw_min: 2000,
            withdraw_max: 200000,
            withdraw_affiliate_day: 9,
            
            pixel_gtm: '',
            
            maintence: true,
        }

        nuxtApp.$settings = defaultSettings;
    }
})
